import Vue from 'vue'
import { hasOwn } from '@simpl/core/utils'
import { scheduleUpdateQueue } from '@simpl/cms-components/utils/update-queue'

export const getValueAtKeyPath = (context: Vue, obj: Record<string, any>, keyPath: string, defaultValue?: any) => {
  const paths = keyPath.split(/\./)
  let subObj = obj

  for (let i = 0; i <= paths.length - 1; i++) {
    const path = paths[i]
    if (!hasOwn(subObj, path) || typeof subObj[path] === 'undefined') {
      return defaultValue
    }
    subObj = subObj[path]
  }

  const path = paths[paths.length - 1]
  return subObj[path] || defaultValue
}

export const setValueAtKeyPath = (context: Vue, obj: Record<string, any>, path: string, value: any) => {
  const parts = path.split(/\./)
  let subObj = obj

  for (let i = 0; i < parts.length - 1; i++) {
    const part = parts[i]
    if (!hasOwn(subObj, part) || typeof subObj[part] === 'undefined') {
      context.$set(subObj, part, {})
    }
    subObj = subObj[part]
  }

  context.$set(subObj, parts[parts.length - 1], value)
  scheduleUpdateQueue()
}
