










































import Vue from 'vue'

export default Vue.extend({
  name: 'UnitInput',

  model: {},

  props: {
    value: String,
    units: {
      type: Array as () => string[],
      default: () => ['px', '%', 'rem', 'vh', 'vw']
    },
    defaultUnit: {
      type: String,
      default: 'px'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      internalValue: this.value
    }
  },

  computed: {
    numericValue: {
      get (): number | string | void {
        return this.internalValue ? parseInt(this.internalValue) : undefined
      },
      set (v: string) {
        this.internalValue = v ? `${v}${this.unitValue}` : ''
      }
    },
    unitValue: {
      get (): string {
        return this.internalValue
          ? this.internalValue.replace(/[^a-zA-Z%]*/, '')
          : this.defaultUnit
      },
      set (v: string) {
        this.internalValue = `${this.numericValue}${v}`
      }
    }
  },

  watch: {
    value (v) {
      this.internalValue = v
    },
    internalValue (v) {
      if (v !== this.value) {
        this.$emit('input', v)
      }
    }
  }
})
