














































































































































import Vue from 'vue'
import { ContentTree } from '../../../types'
import { getValueAtKeyPath, setValueAtKeyPath } from '../../../utils/keypath'
import { DirectionDict } from '@simpl/cms-components/mixins/Layout'
import { MUTATIONS } from '../../../store/consts'

const DISTANCE_PER_STEP = 20

export default Vue.extend({
  name: 'SpacingEditor',

  props: {
    content: Object as () => ContentTree,
    disabled: {
      type: Array,
      default: () => []
    }
  },

  data: function () {
    return {
      keyPath: null! as string,
      startValue: 0,
      minValue: 0,
      startPos: 0,
      vertical: false,
      inverse: false
    }
  },

  computed: {
    margin (): DirectionDict {
      return this.content.data?.properties?.layout?.margin || {}
    },
    padding (): DirectionDict {
      return this.content.data?.properties?.layout?.padding || {}
    },
    disableAll (): boolean {
      return this.disabled.includes('margin') && this.disabled.includes('padding')
    }
  },

  methods: {
    onNumberDragStart (e: MouseEvent, keyPath: string) {
      keyPath = `data.properties.layout.${keyPath}`
      this.keyPath = keyPath
      this.startValue = getValueAtKeyPath(this, this.content, keyPath, 0)
      this.vertical = keyPath.includes('.top') || keyPath.includes('.bottom')
      this.inverse = keyPath.includes('.right') || keyPath.includes('.bottom')
      this.startPos = this.vertical ? e.screenY : e.screenX
      this.minValue = keyPath.includes('padding') ? 0 : -12

      this.applyBodyClasses()

      e.preventDefault()
      e.stopPropagation()

      this.$store.commit(`cms/${MUTATIONS.SET_POINTER_BLOCK_OVERLAY_VISIBLE}`, true)

      window.addEventListener('mousemove', this.onMouseMove)
      window.addEventListener('mouseup', this.onMouseUp)
    },

    onMouseMove (e: MouseEvent) {
      const distance = ((this.vertical ? e.screenY : e.screenX) - this.startPos) * (this.inverse ? -1 : 1)

      const diff = Math.round((distance / DISTANCE_PER_STEP))
      const newVal = Math.min(12, Math.max(this.minValue, this.startValue + diff))
      if (newVal !== getValueAtKeyPath(this, this.content, this.keyPath)) {
        setValueAtKeyPath(this, this.content, this.keyPath, newVal)
      }

      e.preventDefault()
      e.stopPropagation()
    },

    onMouseUp (e: MouseEvent) {
      window.removeEventListener('mousemove', this.onMouseMove)
      window.removeEventListener('mouseup', this.onMouseUp)

      this.removeBodyClasses()

      this.$store.commit(`cms/${MUTATIONS.SET_POINTER_BLOCK_OVERLAY_VISIBLE}`, false)

      e.preventDefault()
      e.stopPropagation()
    },

    applyBodyClasses () {
      document.body.classList.add('number-drag-active')
      if (this.vertical) {
        document.body.classList.add('number-drag-active--vertical')
      }
    },

    removeBodyClasses () {
      document.body.classList.remove('number-drag-active')
      document.body.classList.remove('number-drag-active--vertical')
    },

    isDisabled (spacing: string, direction: string) {
      return this.disabled.includes(spacing) || this.disabled.includes(`${spacing}.${direction}`)
    }
  }
})
