







































import Vue from 'vue'

export default Vue.extend({
  name: 'EditorGroup',

  props: {
    title: String,
    disabled: Boolean
  },

  data () {
    return {
      open: false
    }
  },

  methods: {
    close () {
      this.open = false
    }
  }
})
