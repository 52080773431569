
























































































































































































import Vue from 'vue'
import { ContentTree } from '../../../types'

import SpacingEditor from './SpacingEditor.vue'
import UnitInput from './UnitInput.vue'
import EditorGroup from '../EditorGroup.vue'

import { setValueAtKeyPath, getValueAtKeyPath } from '../../../utils/keypath'
import { SizeSettings, TypographySettings } from '@simpl/cms-components/mixins/Layout'
import { rgbToHex } from '../../../utils/colors'
import { MUTATIONS } from '../../../store/consts'

export default Vue.extend({
  name: 'LayoutEditor',

  components: {
    SpacingEditor,
    UnitInput,
    EditorGroup
  },

  data: function () {
    return {
      isMenuOpen: false,
      sizes: ['width', 'height', 'minWidth', 'minHeight', 'maxWidth', 'maxHeight'],
      alignments: {
        align: [{
          icon: 'mdi-align-horizontal-left',
          text: 'alignLeft',
          value: 'start'
        }, {
          icon: 'mdi-align-horizontal-center',
          text: 'alignCenter',
          value: 'center'
        }, {
          icon: 'mdi-align-horizontal-right',
          text: 'alignRight',
          value: 'end'
        }],
        justify: [{
          icon: 'mdi-align-vertical-top',
          text: 'justifyTop',
          value: 'start'
        }, {
          icon: 'mdi-align-vertical-center',
          text: 'justifyCenter',
          value: 'center'
        }, {
          icon: 'mdi-align-vertical-bottom',
          text: 'justifyBottom',
          value: 'end'
        }]
      }
    }
  },

  computed: {
    component (): Vue & { content: ContentTree } {
      return this.$store.getters['cms/selectedComponent']
    },
    size (): SizeSettings {
      return this.component?.content.data?.properties?.layout?.size || {}
    },
    typography (): TypographySettings {
      return this.component?.content.data?.properties?.layout?.typography || {}
    },
    elementFontSize (): string {
      if (!this.component) return ''
      return window.getComputedStyle(this.component!.$el as HTMLElement).fontSize
    },
    elementLineHeight (): string {
      if (!this.component) return ''
      return window.getComputedStyle(this.component!.$el as HTMLElement).lineHeight
    },
    elementTextColor (): string {
      if (!this.component) return ''
      return rgbToHex(window.getComputedStyle(this.component!.$el as HTMLElement).color) || ''
    },
    disabled (): boolean {
      return !!this.component?.content.isLocal
    }
  },

  watch: {
    isMenuOpen (v: boolean) {
      this.$store.commit(`cms/${MUTATIONS.SET_POINTER_BLOCK_OVERLAY_VISIBLE}`, v)
    }
  },

  methods: {
    getValue (path: string) {
      path = `data.properties.layout.${path}`
      return getValueAtKeyPath(this, this.component.content, path)
    },
    setValue (path: string, value: any) {
      path = `data.properties.layout.${path}`
      const prev = getValueAtKeyPath(this, this.component.content, path)
      setValueAtKeyPath(this, this.component.content, path, value)
      if (typeof prev === 'undefined') {
        this.$forceUpdate()
      }
    },
    isDisabled (property: string, setting?: string) {
      if (this.disabled) return true

      const disabledProperties = this.component.$options!.cms?.layout?.disabled

      return disabledProperties
        ? disabledProperties.indexOf(property) > -1 ||
          disabledProperties.indexOf(`${property}.${setting}`) > -1
        : false
    }
  }
})
